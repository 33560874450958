import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../../store/slice/ordersSlice";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { viewIcon, editIcon, deleteIcon } from "../../icons";
import Loader from "../../component/Common/Loader";
import ViewOrder from "./ViewOrder";
import DeleteOrderModal from "./DeleteOrderModal";
import { defaultTimeZone } from "../../helpers/commonFunction";
import DatePicker from "react-datepicker";
import ChangeStatusModal from "./ChangeStatusModal";
import { useRef } from "react";
import Pagination from "../../component/Pagination/Pagination";

const Order = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const params = useParams();
  const ref = useRef(false);
  const navigate = useNavigate();
  const { page_number } = params;
  const context = useOutletContext();

  const ordersList = useSelector((state) => state.order.orderList?.data);
  const ordersCount = useSelector((state) => state.order.orderList?.count);
  const ordersLoader = useSelector((state) => state.order.loader);

  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [viewModalData, setViewModalData] = useState("");
  const [viewModalId, setViewModalId] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [tabStatus, setTabStatus] = useState("NEW");

  const [orderDate, setOrderDate] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);

  useEffect(() => {
    if (ref.current) {
      // navigate(`/order/page/1`);
      if (page_number !== 1) {
        navigate(`/order/page/1`);
        //New line added
        setPageN(0);
      }

      if (+pageN === 0) {
        if (tabStatus === "CANCELLED") {
          context.setCancelOrderCount(0);
        }
        if (tabStatus === "NEW") {
          context.setCount(0);
        }
        // console.log("CALLEDDD ===> WHEN TAB FILTER CHANGED");
        dispatch(
          getOrderList({
            order_status: tabStatus,
            order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
            start: (page_number - 1) * 10,
            limit: 10,
          })
        );
      }
    }
  }, [tabStatus]);

  useEffect(() => {
    if (ref.current) {
      navigate(`/order/page/1`);
      // console.log("CALLEDDD ===> WHEN DATE CHANGED");
      dispatch(
        getOrderList({
          order_status: tabStatus,
          order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
          start: (page_number - 1) * 10,
          limit: 10,
        })
      );
    }
  }, [orderDate]);

  useEffect(() => {
    if (ref.current) {
      // console.log("CALLEDDD ===> WHEN PAGE CHANGED");
      dispatch(
        getOrderList({
          order_status: tabStatus,
          order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
          start: (page_number - 1) * 10,
          limit: 10,
        })
      );
    }
  }, [pageN]);

  useEffect(() => {
    if (ref.current && page_number == 1 && tabStatus === "NEW") {
      context.setCount(0);
      // console.log("CALLEDDD ===> WHEN COUNT CHANGED");
      setOrderDate("");
      dispatch(
        getOrderList({
          order_status: "NEW",
          start: (page_number - 1) * 10,
          limit: 10,
        })
      );
    }
    if (ref.current && page_number == 1 && tabStatus === "CANCELLED") {
      context.setCancelOrderCount(0);
      // console.log("CALLEDDD ===> WHEN COUNT CHANGED");
      setOrderDate("");
      dispatch(
        getOrderList({
          order_status: "CANCELLED",
          start: (page_number - 1) * 10,
          limit: 10,
        })
      );
    }
  }, [context.count, context.cancelOrderCount]);

  useEffect(() => {
    context.setCount(0);
    // console.log("CALLEDDD ===> INITIAL RENDERED");

    if (tabStatus === "NEW" && page_number !== 1) {
      //New line added
      navigate(`/order/page/1`);
      setPageN(0);
    }

    dispatch(
      getOrderList({
        order_status: tabStatus,
        order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
        start: (page_number - 1) * 10,
        limit: 10,
      })
    );

    context.setCount(0);
    // context.setCancelOrder(false);
    ref.current = true;
  }, []);

  const viewOrderHandler = (item) => {
    setViewModal(true);
    setViewModalId(item.id);
  };

  const viewOrderClose = () => {
    setViewModal(false);
    setViewModalId("");
  };

  const showChangeStatusModal = (item) => {
    setChangeStatusModal(true);
    setViewModalData(item);
  };

  const closeChangeStatusModal = () => {
    setChangeStatusModal(false);
    setViewModalData("");
  };

  const showDeleteModal = (item) => {
    setDeleteId(item.id);
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteId("");
    setDeleteModal(false);
  };

  // const statusChangeHandler = (e) => {
  //   setOrderStatus(e);
  // };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const currency = "฿";

  const changeTabStatusHandler = (e) => {
    setTabStatus(e.target.value);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("table.orders")}</h2>
        </div>
        <div className="oc-whitebox-content">
          <div className="user_data_search_select_box_row user_data_search_select_with_tabs">
            <div
              className="tab span_2"
              onChange={(e) => changeTabStatusHandler(e)}
            >
              <div className="multiple_tab_input_container">
                <input
                  label={t("service.pending")}
                  type="radio"
                  id="CREDITED"
                  name="forAll"
                  value={"NEW"}
                  checked={tabStatus === "NEW"}
                  readOnly
                />
                {context.count !== 0 && (
                  <span className="single_tab_input_count">
                    {context.count}
                  </span>
                )}
              </div>
              <input
                label={t("service.accepted")}
                type="radio"
                id="DEBITED"
                name="forAll"
                value={"ACCEPTED"}
                checked={tabStatus === "ACCEPTED"}
                readOnly
              />
              <input
                label={t("service.process")}
                type="radio"
                id="DEBITED"
                name="forAll"
                value={"PROCESSING"}
                checked={tabStatus === "PROCESSING"}
                readOnly
              />

              <input
                label={t("service.completed")}
                type="radio"
                id="DEBITED"
                name="forAll"
                value={"COMPLETE"}
                checked={tabStatus === "COMPLETE"}
                readOnly
              />
              <input
                label={t("service.picked_up")}
                type="radio"
                id="DEBITED"
                name="forAll"
                value={"PICKED_UP"}
                checked={tabStatus === "PICKED_UP"}
                readOnly
              />
              <div className="multiple_tab_input_container">
                <input
                  className=""
                  label={t("service.Cancelled")}
                  type="radio"
                  id="DEBITED"
                  name="forAll"
                  value={"CANCELLED"}
                  checked={tabStatus === "CANCELLED"}
                  readOnly
                />
                {context.cancelOrderCount !== 0 && (
                  <span className="single_tab_input_count">
                    {context.cancelOrderCount}
                  </span>
                )}
              </div>
            </div>

            <div className="user_data_search h-35">
              <DatePicker
                isClearable={orderDate}
                placeholderText={`${t("form.order_by_date")}`}
                selected={orderDate}
                onChange={(date) => {
                  setOrderDate(date);
                }}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>
          </div>

          {ordersLoader ? (
            <Loader />
          ) : ordersList?.length === 0 ? (
            <h2 className="ta_c c-grey">No results found</h2>
          ) : (
            <div className="custom_data_table_content custom_data_order_content">
              <div className="custom_data_table_order_list">
                <div className="oc-order-receive-table">
                  <table>
                    <tbody>
                      {ordersList?.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td className="oc-order-receive-table-ordernumber-td">
                              <div className="oc-order-receive-table-ordernumber">
                                Order No: {item.order_number}
                              </div>
                            </td>
                            <td>
                              <div className="oc-order-receive-table-time-text">
                                {item.user_name}
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                  {moment
                                    .utc(item.order_date, "YYYYMMDD HH:mm:ss")
                                    .clone()
                                    .tz(defaultTimeZone)
                                    .fromNow()}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="oc-order-receive-table-price-text">
                                {item.orders.map((items, index) => {
                                  return (
                                    ((item.order_status !== "CANCELLED" &&
                                      items.delete_status === "0") ||
                                      item.order_status === "CANCELLED") && (
                                      <span
                                        key={index}
                                        className="orders-menu-item-column-table"
                                      >
                                        <div className="oc-order-receive-table-time-text">
                                          {items.menu_name} × {items.quantity}
                                          <div className="add-on-container">
                                            {JSON.parse(items.addons)
                                              ?.length !== 0 &&
                                              JSON.parse(items.addons)?.map(
                                                (addonItem, i) => {
                                                  return (
                                                    <span key={i} className="">
                                                      {addonItem.addon_name +
                                                        (JSON.parse(
                                                          items.variations
                                                        )?.length === 0 &&
                                                        JSON.parse(
                                                          items.custom_options
                                                        )?.length === 0
                                                          ? i ==
                                                            JSON.parse(
                                                              items.addons
                                                            )?.length -
                                                              1
                                                            ? ""
                                                            : ","
                                                          : ",")}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            {JSON.parse(items.variations)
                                              ?.length !== 0 &&
                                              JSON.parse(items.variations)?.map(
                                                (variation, i) => {
                                                  return (
                                                    <span key={i} className="">
                                                      {variation.item_name +
                                                        (JSON.parse(
                                                          items.custom_options
                                                        )?.length === 0
                                                          ? i ==
                                                            JSON.parse(
                                                              items.variations
                                                            )?.length -
                                                              1
                                                            ? ""
                                                            : ","
                                                          : ",")}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            {JSON.parse(items.custom_options)
                                              ?.length !== 0 &&
                                              JSON.parse(
                                                items.custom_options
                                              )?.map((option, i) => {
                                                return option.item.map(
                                                  (val, j) => {
                                                    return (
                                                      <span
                                                        key={j}
                                                        className=""
                                                      >
                                                        {val.item_name +
                                                          (i ==
                                                          option.item.length - 1
                                                            ? ""
                                                            : ",")}
                                                      </span>
                                                    );
                                                  }
                                                );
                                              })}
                                          </div>
                                        </div>
                                      </span>
                                    )
                                  );
                                })}
                              </div>
                            </td>

                            <td>
                              <div className="oc-order-receive-table-price-text">
                                {item.total + " " + currency}
                              </div>
                            </td>
                            <td className="oc-order-receive-table-btn-td">
                              <div className="custom_data_table_view_edit_btn_item_row custom_data_table_order_list_content_btn_item_row">
                                <div className="tooltip">
                                  <button
                                    className="custom_data_table_view_edit_item_btn"
                                    onClick={() => viewOrderHandler(item)}
                                  >
                                    {viewIcon}
                                  </button>

                                  <span className="tooltiptext">
                                    {t("common.view")}
                                  </span>
                                </div>

                                {item.order_status !== "CANCELLED" &&
                                  item.order_status !== "DELIVERED" && (
                                    <div className="tooltip">
                                      <button
                                        className="custom_data_table_view_edit_item_btn"
                                        onClick={() =>
                                          showChangeStatusModal(item)
                                        }
                                      >
                                        {editIcon}
                                      </button>

                                      <span className="tooltiptext">
                                        {t("common.edit")}
                                      </span>
                                    </div>
                                  )}

                                <div className="tooltip">
                                  <button
                                    className="custom_data_table_view_edit_item_btn"
                                    onClick={() => showDeleteModal(item)}
                                  >
                                    {deleteIcon}
                                  </button>
                                  <span className="tooltiptext">
                                    {t("common.delete")}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {ordersList?.length > 0 && (
            <Pagination
              totalRecords={ordersCount}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
      {viewModal && (
        <ViewOrder
          show={viewModal}
          viewOrderClose={viewOrderClose}
          setViewModal={setViewModal}
          viewModalId={viewModalId}
          orderDate={orderDate}
          orderStatusFilter={tabStatus}
        />
      )}
      <DeleteOrderModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        handleDeleteModalClose={handleDeleteModalClose}
        orderDate={orderDate}
        orderStatusFilter={tabStatus}
        pageNumber={page_number}
        totalCount={ordersCount}
      />
      <ChangeStatusModal
        closeChangeStatusModal={closeChangeStatusModal}
        show={changeStatusModal}
        orderData={viewModalData}
        setChangeStatusModal={setChangeStatusModal}
        orderDate={orderDate}
        orderStatusFilter={tabStatus}
        pageNumber={page_number}
      />
    </>
  );
};

export default Order;
