import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getTag, updateTag } from "../../store/slice/tagSlice";
import { useNavigate } from "react-router";

const DeleteTagModal = ({
  tagDeleteModal,
  setTagDeleteModal,
  tagDeleteId,
  setTagDeleteId,
  handleClose,
  pageNumber,
  totalCount,
}) => {
  const showHideClassName = tagDeleteModal && "user_detail_modal_show";
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const deleteTagHandler = () => {
    const data = {
      delete_status: "1",
    };

    dispatch(
      updateTag({
        data,
        id: tagDeleteId,
        deleteTag: true,
        toast: t("toast.delete_tag"),
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            setTagDeleteId("");
            setTagDeleteModal(false);
            if (
              totalCount &&
              totalCount[totalCount?.length - 1] == 1 &&
              +pageNumber !== 1
            ) {
              navigate(`/tag/page/${pageNumber - 1}`);
            } else {
              dispatch(getTag({ start: (pageNumber - 1) * 10, limit: 10 }));
            }
          }
        },
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("modal.delete.delete_tag")}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.delete_tag_desc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteTagHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteTagModal;
