import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";

export const getDashboardDetail = createAsyncThunk(
  "getDashboardDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph-restaurant/reports/dashboard`,
        data.data,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// export const userReportDetail = createAsyncThunk(
//   "userReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-users`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const feedbackReportDetail = createAsyncThunk(
//   "feedbackReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-feedback`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const serviceReportDetail = createAsyncThunk(
//   "serviceReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-service`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const eventReportDetail = createAsyncThunk(
//   "eventReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-events`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const contactReportDetail = createAsyncThunk(
//   "contactReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-contacts`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const announcementsReportDetail = createAsyncThunk(
//   "announcementsReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-announcements`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const promotionReportDetail = createAsyncThunk(
//   "promotionReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-promotions`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

// export const visitorInviteReportDetail = createAsyncThunk(
//   "visitorInviteReportDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.post(
//         `/onecity/reports/feature-invites`,
//         data.data,
//         authHeaders()
//       );
//       console.log("response", response);
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       console.log("error", error.response);
//     }
//   }
// );

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardNumbers: {},
    userReport: [],
    feedbackReport: {},
    serviceReport: {},
    eventReport: {},
    contactReport: {},
    announcementReport: {},
    promotionReport: {},
    visitorInviteReport: {},
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDashboardDetail.fulfilled, (state, action) => {
      state.loader = false;
      state.dashboardNumbers = action.payload;
    });
    builder.addCase(getDashboardDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // builder.addCase(userReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(userReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.userReport = action.payload;
    // });
    // builder.addCase(userReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(feedbackReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(feedbackReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.feedbackReport = action.payload;
    // });
    // builder.addCase(feedbackReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(serviceReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(serviceReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.serviceReport = action.payload;
    // });
    // builder.addCase(serviceReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(eventReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(eventReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.eventReport = action.payload;
    // });
    // builder.addCase(eventReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(contactReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(contactReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.contactReport = action.payload;
    // });
    // builder.addCase(contactReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(announcementsReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(announcementsReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.announcementReport = action.payload;
    // });
    // builder.addCase(announcementsReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(promotionReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(promotionReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.promotionReport = action.payload;
    // });
    // builder.addCase(promotionReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(visitorInviteReportDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(visitorInviteReportDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.visitorInviteReport = action.payload;
    // });
    // builder.addCase(visitorInviteReportDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });
  },
});

export default dashboardSlice.reducer;
