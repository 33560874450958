import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getResImg,
  updateResImg,
} from "../../store/slice/restaurantImagesSlice";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const DeleteResImgModal = ({
  deleteModal,
  handleClose,
  setDeleteModal,
  checkValue,
  setCheckValue,
}) => {
  const showHideClassName = deleteModal && "user_detail_modal_show";
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const deleteResImgHandler = () => {
    const data = {
      delete_status: "1",
      id: checkValue,
    };
    dispatch(
      updateResImg({
        data,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            setDeleteModal(false);
            setCheckValue([]);
            // if (
            //   totalCount &&
            //   totalCount - 18 * +(pageNumber - 1) == 1 &&
            //   +pageNumber !== 1
            // ) {
            //   navigate(`/images/page/${pageNumber - 1}`);
            // } else {
            dispatch(getResImg({}));
            toast.error(t("toast.del_res_img"), {
              autoClose: 3000,
            });
            // start: (pageNumber - 1) * 18, limit: 18
          }
        },
      })
    );
  };
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("common.delete") + " " + t("table.images")}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("toast.delete_selected_img")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteResImgHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteResImgModal;
