import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

// Create Category
export const createTag = createAsyncThunk(
  "createTag",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph-restaurant/tag`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(`${routes.tag}`);
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// Update Category
export const updateTag = createAsyncThunk(
  "updateTag",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph-restaurant/tag/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.deleteTag) {
          toast.error(data.toast, {
            autoClose: 3000,
          });
        } else {
          toast.success(data.toast, {
            autoClose: 3000,
          });
        }
        // data.navigate(`${routes.category}`);
        return data.cb(null, response.data);
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const getTag = createAsyncThunk("getTag", async (data, thunkAPI) => {
  try {
    const response = await Axios.get(
      `/sph-restaurant/tag?start=${data.start || 0}&limit=${data.limit || 10}`,
      authHeaders()
    );
    return { data: response.data, count: response.headers["x-total-count"] };
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

const initialState = {
  tagList: [],
  createTagList: "",
  updateTagList: "",
  loader: false,
  error: "",
  user: "",
};
const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //get tag
    builder.addCase(getTag.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTag.fulfilled, (state, action) => {
      state.loader = false;
      state.tagList = action.payload;
    });
    builder.addCase(getTag.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //create tag
    builder.addCase(createTag.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createTag.fulfilled, (state, action) => {
      state.loader = false;
      state.createTagList = action.payload;
    });
    builder.addCase(createTag.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //update tag
    builder.addCase(updateTag.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateTag.fulfilled, (state, action) => {
      state.loader = false;
      state.updateTagList = action.payload;
    });
    builder.addCase(updateTag.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export default tagSlice.reducer;
