import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/slice/userSlice";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "../../css/LoginPage.css";
import { useForm } from "../../hooks/useForm";
import faviconWhite from "../../images/onecitylogo/FaviconWhite.png";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { withoutMetadata } from "../../component/Common/PageComponent/LoginPageComponents";
import Loader from "../../component/Common/Loader";
import {
  phoneValidation,
  validateEmail,
} from "../../component/Common/PageComponent/LoginPageComponents";
import LoginPageForm from "../../component/Common/PageComponent/FormComponent/LoginPageForm";

toast.configure();
const LoginPage = () => {
  const dispatch = useDispatch();

  const loginLoader = useSelector((state) => state.user.loader);

  const initialFValues = {
    phone_number: "",
    phone_number_error: "",
    email: "",
    password: "",
    password_error: "",
    inputType: "password",
    emailPhoneToggle: true,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    return validateEmail(temp, fieldValues, values, setErrors);
  };

  const phoneValidate = (fieldValues = values) => {
    var temp = { ...errors };
    return phoneValidation(temp, fieldValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    setValues({ ...values, password_error: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.password]);

  const parsedPhone =
    values.phone_number &&
    withoutMetadata(parsePhoneNumber(values.phone_number));

  const signInHandler = (e) => {
    e.preventDefault();
    if (values.emailPhoneToggle ? validate() : phoneValidate()) {
      let data;
      !values.emailPhoneToggle
        ? (data = {
            phone_number: parsedPhone.nationalNumber,
            country_code: parsedPhone.countryCallingCode,
            password: values.password,
            app_type: 2,
          })
        : (data = {
            email: values.email,
            password: values.password,
            // app_type: 2,
          });
      dispatch(loginUser(data));
    }
  };

  if (loginLoader) {
    return <Loader />;
  }

  return (
    <section className="login_section">
      <div className="login_content">
        <div className="login_top_content">
          <h3>Welcome Back !</h3>
          <p>Sign in to Siam Patumwan House</p>
        </div>
        <div className="login_input_logo_content">
          <div className="login_logo">
            <img src={faviconWhite} alt="company_logo" />
          </div>
          <form className="login_input_content">
            <LoginPageForm
              values={values}
              errors={errors}
              setErrors={setErrors}
              setValues={setValues}
              handleInputChange={handleInputChange}
              signInHandler={signInHandler}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
