import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

// Create Cutlery
export const createCutlery = createAsyncThunk(
  "createCutlery",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph-restaurant/cutlery`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.cutlery}`);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// Update Cutlery
export const updateCutlery = createAsyncThunk(
  "updateCutlery",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph-restaurant/cutlery/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });

        data.navigate(`${routes.cutlery}`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const getCutlery = createAsyncThunk(
  "getCutlery",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph-restaurant/cutlery?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  cutleryList: [],
  createCutlery: "",
  updateCutlery: "",
  loader: false,
  error: "",
  user: "",
};
const cutlerySlice = createSlice({
  name: "cutlery",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //get Cutlery
    builder.addCase(getCutlery.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCutlery.fulfilled, (state, action) => {
      state.loader = false;
      state.cutleryList = action.payload;
    });
    builder.addCase(getCutlery.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //create Cutlery
    builder.addCase(createCutlery.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createCutlery.fulfilled, (state, action) => {
      state.loader = false;
      state.createCutlery = action.payload;
    });
    builder.addCase(createCutlery.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //update Cutlery
    builder.addCase(updateCutlery.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateCutlery.fulfilled, (state, action) => {
      state.loader = false;
      state.updateCutlery = action.payload;
    });
    builder.addCase(updateCutlery.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export default cutlerySlice.reducer;
