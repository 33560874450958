import React, { useEffect } from "react";
import Button from "../../component/Common/Button";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getOrderList, updateOrder } from "../../store/slice/ordersSlice";
import moment from "moment";
import { toast } from "react-toastify";

const ChangeStatusModal = ({
  closeChangeStatusModal,
  orderData,
  setChangeStatusModal,
  orderDate,
  orderStatusFilter,
  show,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = show && "user_detail_modal_show";
  const { t } = useTranslation("common");

  const [orderStatus, setOrderStatus] = useState(
    orderData.order_status ? orderData.order_status : ""
  );

  useEffect(() => {
    setOrderStatus(orderData?.order_status);
  }, [orderData]);

  const statusList = [
    {
      display_name: t("service.pending"),
      value: "PENDING",
      disableValue: orderData.order_status === "PENDING" ? true : false,
    },
    {
      display_name: t("service.accepted"),
      value: "ACCEPTED",
      disableValue:
        orderData.order_status === "PENDING" ||
        orderData.order_status === "ACCEPTED"
          ? true
          : false,
    },
    {
      display_name: t("service.process"),
      value: "PROCESSING",
      disableValue:
        orderData.order_status === "ACCEPTED" ||
        orderData.order_status === "PROCESSING"
          ? true
          : false,
    },
    {
      display_name: t("service.completed"),
      value: "COMPLETE",
      disableValue:
        orderData.order_status === "PROCESSING" ||
        orderData.order_status === "COMPLETE"
          ? true
          : false,
    },
    {
      display_name: t("service.picked_up"),
      value: "DELIVERED",
      disableValue:
        orderData.order_status === "COMPLETE" ||
        orderData.order_status === "DELIVERED"
          ? true
          : false,
    },
    {
      display_name: t("service.Cancelled"),
      value: "CANCELLED",
      disableValue:
        orderData.order_status === "DELIVERED" ||
        orderData.order_status === "COMPLETE"
          ? false
          : true,
    },
  ];

  const changeStatusHandoler = () => {
    let data = {
      order_status: orderStatus,
    };
    dispatch(
      updateOrder({
        id: orderData.id,
        data,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            dispatch(
              getOrderList({
                start: (pageNumber - 1) * 10,
                limit: 10,
                order_status: orderStatusFilter,
                order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
              })
            );
            setChangeStatusModal(false);
            toast.success(t("toast.update_order"), {
              autoClose: 3000,
            });
          }
        },
      })
    );
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={closeChangeStatusModal}
      ></div>
      <section
        className={`user_detail_modal_main status_change_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section tag-container">
          <h2 className="announcement_page-view-modal">
            {t("service.status")}
          </h2>
          <div className="inquiry_complaint_detail_body view-modal_container view-modal_multi_btn_container">
            <div className="span_2">
              {/* <h2 className="inquiry_complaint_detail_heading">Status</h2> */}
              <div className="visitor-button-row visitor-button-row1">
                {statusList.map((btn, i) => {
                  return (
                    <button
                      key={i}
                      className={`visitor-button equal_btn_row ${
                        orderStatus === btn.value && "is-active"
                      } ${!btn.disableValue && "disabled-visitor-button"}`}
                      value={btn.value}
                      onClick={(e) => setOrderStatus(e.target.value)}
                      disabled={!btn.disableValue}
                    >
                      {btn.display_name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="delete_feedback_btn-container">
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={changeStatusHandoler}
              text={t("common.save")}
            />
            <Button
              type="button"
              onClick={closeChangeStatusModal}
              buttonClassName="comman_btn secondary_btn"
              className="project_submit_bottom_btn center_back_btn"
              text={t("common.close")}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangeStatusModal;
