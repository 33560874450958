import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

// Create Menu
export const createMenuItem = createAsyncThunk(
  "createMenuItem",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph-restaurant/menu`,
        data.data,
        authHeaders()
      );

      if (response.status === 200 || (response.status === 201 && !data.cb)) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.menuItem}`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// Update Menu
export const updateMenuItem = createAsyncThunk(
  "updateMenuItem",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph-restaurant/menu/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(`${routes.menuItem}`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// Get Menu List
export const getMenuItemList = createAsyncThunk(
  "getMenuItemList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph-restaurant/menu?category_name=${data.category_name || ""}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

// Get Menu by ID
export const getMenuItemById = createAsyncThunk(
  "getMenuItemById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph-restaurant/menu/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const lastTabData = createAsyncThunk("lastTabData", async (data) => {
  return data;
});
export const clearLastTabData = createAsyncThunk(
  "clearLastTabData",
  async (data) => {
    return;
  }
);

const initialState = {
  menuItemList: [],
  menuDetail: {},
  loader: false,
  createMenuComponent: "",
  updateMenuComponent: "",
  lastTab: "",
};
const menuItemSlice = createSlice({
  name: "menuItem",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //Menu List
    builder.addCase(getMenuItemList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMenuItemList.fulfilled, (state, action) => {
      state.loader = false;
      state.menuItemList = action.payload;
    });
    builder.addCase(getMenuItemList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //Add Menu
    builder.addCase(createMenuItem.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createMenuItem.fulfilled, (state, action) => {
      state.loader = false;
      state.createMenuComponent = action.payload;
    });
    builder.addCase(createMenuItem.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //Update Menu
    builder.addCase(updateMenuItem.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateMenuItem.fulfilled, (state, action) => {
      state.loader = false;
      state.updateMenuComponent = action.payload;
    });
    builder.addCase(updateMenuItem.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //Get Menu by Id
    builder.addCase(getMenuItemById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMenuItemById.fulfilled, (state, action) => {
      state.loader = false;
      state.menuDetail = action.payload;
    });
    builder.addCase(getMenuItemById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //
    //set tab
    builder.addCase(lastTabData.fulfilled, (state, action) => {
      state.loader = false;
      state.lastTab = action.payload;
    });
    //
    //clear tab
    builder.addCase(clearLastTabData.fulfilled, (state, action) => {
      state.loader = false;
      state.lastTab = "";
    });
    //
  },
});
export default menuItemSlice.reducer;
