import React from "react";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { createTag, getTag, updateTag } from "../../store/slice/tagSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const AddTagModal = ({
  tagForm,
  tagName,
  tagNameErr,
  setTagForm,
  setTagName,
  setTagNameErr,
  handleClose,
  tagFormEdit,
  setTagFormEdit,
  tagId,
  setTagId,
  pageNumber,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = tagForm && "user_detail_modal_show";
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const validation = () => {
    let isValid = false;
    if (!tagName) {
      setTagNameErr("Tag Name is required");
    } else {
      isValid = true;
    }
    return isValid;
  };

  const addTagHandler = () => {
    if (validation()) {
      const data = {
        tag_name: tagName,
      };
      tagFormEdit
        ? dispatch(
            updateTag({
              data,
              id: tagId,
              toast: t("toast.update_tag"),
              cb: (err, res) => {
                if (err) {
                  // console.log("err", err);
                } else {
                  setTagName("");
                  setTagId("");
                  setTagForm(false);
                  setTagFormEdit(false);
                  dispatch(getTag({ start: (pageNumber - 1) * 10, limit: 10 }));
                }
              },
            })
          )
        : dispatch(
            createTag({
              data,
              toast: t("toast.create_tag"),
              cb: (err, res) => {
                if (err) {
                  // console.log("err", err);
                } else {
                  setTagName("");
                  setTagId("");
                  setTagForm(false);
                  setTagFormEdit(false);
                  if (+pageNumber !== 1) {
                    navigate("/tag/page/1");
                  } else {
                    dispatch(getTag({ start: 0, limit: 10 }));
                  }
                }
              },
            })
          );
    }
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section tag-container">
          <h2 className="announcement_page-view-modal">
            {tagFormEdit
              ? t("common.edit") + " " + t("common.tag")
              : t("common.add") + " " + t("common.tag")}
          </h2>
          <div className="">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.enter_tag_name")}
              label={t("form.tag_name")}
              id="tag_name"
              name="tag_name"
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
                setTagNameErr("");
              }}
              error={tagNameErr || ""}
            />
          </div>
          <div className="delete_feedback_btn-container">
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={addTagHandler}
              text={tagFormEdit ? t("common.save") : t("common.add")}
            />
            <Button
              type="button"
              onClick={handleClose}
              buttonClassName="comman_btn secondary_btn"
              className="project_submit_bottom_btn center_back_btn"
              text={t("common.close")}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AddTagModal;
