import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

// Create Category
export const createResImg = createAsyncThunk(
  "createResImg",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/sph-restaurant/image`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(`${routes.category}`);
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const updateResImg = createAsyncThunk(
  "updateResImg",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/sph-restaurant/image`,
        data.data,
        authHeaders()
      );
      if ((response.status === 200 || response.status === 201) && data.cb) {
        toast.error(data.toast, {
          autoClose: 3000,
        });

        return data.cb(null, response.data);
      }
      data.navigate(`${routes.images}`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

export const getResImg = createAsyncThunk(
  "getResImg",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/sph-restaurant/image?start=${data.start || 0}&limit=${
          data.limit || 100000000
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  resImgList: [],
  createResImage: "",
  updateResImage: "",
  loader: false,
  error: "",
  user: "",
};
const restaurantImagesSlice = createSlice({
  name: "resImg",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //get res image
    builder.addCase(getResImg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getResImg.fulfilled, (state, action) => {
      state.loader = false;
      state.resImgList = action.payload;
    });
    builder.addCase(getResImg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //create res image
    builder.addCase(createResImg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createResImg.fulfilled, (state, action) => {
      state.loader = false;
      state.createResImage = action.payload;
    });
    builder.addCase(createResImg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //update res image
    builder.addCase(updateResImg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateResImg.fulfilled, (state, action) => {
      state.loader = false;
      state.updateResImage = action.payload;
    });
    builder.addCase(updateResImg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export default restaurantImagesSlice.reducer;
