import React, { useState } from "react";
import { checkIcon, crossIcon, deleteIcon, editIcon } from "../../icons";
import { Link, useLocation, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, updateCategory } from "../../store/slice/categorySlice";
import moment from "moment";
import DeleteCategoryModal from "./DeleteCategoryModal";
import Loader from "../../component/Common/Loader";
import { defaultTimeZone } from "../../helpers/commonFunction";
import Pagination from "../../component/Pagination/Pagination";
import { useRef } from "react";
import { toast } from "react-toastify";

const Category = () => {
  const dispatch = useDispatch();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const location = useLocation();

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const categoryLoader = useSelector((state) => state.category.loader);

  const categories = useSelector((state) => state.category.categoryList?.data);
  const categoriesCount = useSelector(
    (state) => state.category.categoryList?.count
  );

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (ref.current && !location?.state?.updateForm) {
      dispatch(getCategory({ start: (page_number - 1) * 10, limit: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getCategory({ start: (page_number - 1) * 10, limit: 10 }));
    ref.current = true;
  }, []);

  const deleteCategoryHandler = (item) => {
    setDeleteModal(true);
    setDeleteId(item.id);
  };

  const handleCloseHandler = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const activeCategoryHandler = (item) => {
    const data = {
      active_status: item.active_status === "1" ? "0" : "1",
    };
    dispatch(
      updateCategory({
        data,
        id: item.id,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            toast.success(
              item.active_status === "0"
                ? t("form.category") + " " + t("toast.available_msg")
                : t("form.category") + " " + t("toast.unavailable_msg"),
              {
                autoClose: 2000,
              }
            );
            dispatch(getCategory({ start: (page_number - 1) * 10, limit: 10 }));
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("common.category")}</h2>
          <div className="creat_edit_project_btn_row">
            <Link
              to={routes.createCategory}
              state={{ pageNumber: page_number }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>
        {categoryLoader ? (
          <Loader />
        ) : categories?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <>
            <div className="custom_data_table_content">
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    {/* <th className="custom_data_table_heading">
                      {t("table.icon")}
                    </th> */}
                    <th className="custom_data_table_heading">
                      {t("table.name")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.is_available")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.createdAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.updatedAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {categories?.map((item, index) => {
                    return (
                      <tr className="custom_data_table_row" key={item.id}>
                        {/* <td className="custom_data_table_item table_item">
                          <img
                            src={
                              process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.category_image
                            }
                            alt={"category_icon"}
                            className={`custom_data_table_img cursor_pointer_image_view`}
                            onClick={() => {
                              setImgZoom(true);
                              setImgSrc(
                                process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  item.category_image
                              );
                            }}
                          />
                        </td> */}
                        <td className="custom_data_table_item table_item">
                          {item.category_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.active_status === "1" ? checkIcon : crossIcon}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.created_at &&
                            moment
                              .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.updated_at
                            ? moment
                                .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <button
                              className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                                item.active_status === "1" ? "red" : "green"
                              }`}
                              // className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77`}
                              onClick={() => activeCategoryHandler(item)}
                            >
                              {item.active_status === "0"
                                ? t("table.available")
                                : t("table.un_available")}
                            </button>
                            <div className="tooltip">
                              <Link
                                to={`${routes.category}/edit/${item.id}`}
                                state={{
                                  category: item,
                                  pageNumber: page_number,
                                }}
                                className="custom_data_table_view_edit_item_btn"
                              >
                                {editIcon}
                              </Link>
                              <span className="tooltiptext">
                                {t("common.edit")}
                              </span>
                            </div>
                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteCategoryHandler(item)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">
                                {t("common.delete")}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {categories?.length > 0 && (
              <Pagination
                totalRecords={categoriesCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
        <DeleteCategoryModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
          handleClose={handleCloseHandler}
          pageNumber={page_number}
          totalCount={categoriesCount}
        />
      </div>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="table img" />
      </div>
    </>
  );
};

export default Category;
